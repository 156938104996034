import React from 'react'

function Footer() {
  return (
    <div className='footer flex justify-center items-start bg-[#e1862a] py-5 text-white text-left' style={{minHeight: '400px', width: '100%'}}>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>About Us</p>
        </div>
        <div>
          <p>At the Ghana Influential Youth Awards Festival, we are on a mission to empower and impact the youth across our beloved country to foster national development through their innovative initiatives and transformative contributions. Our goal is to recognize and celebrate the outstanding achievements of young leaders who are driving positive change and making a significant impact on Ghana’s journey towards progress and prosperity.
           </p>
        </div>
      </section>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>Contact Us</p>
        </div>
        <div className="flex flex-col w-full items-start text-left justify-between h-40">
          <p className='text-left'>Accra, Ghana</p>
 
          <p className='text-left'>+233204933598/+233548503799</p>
          <p className='text-left'>ghanainfluentialyouthawards@gmail.com</p>
        </div>
      </section>
    </div>
  )
}

export default Footer