import React from 'react'
import { Link } from 'react-router-dom'




function Card({data, hideButton}) {
  return (
    <div className='cardd flex flex-col m-3 mb-5 shadow-lg rounded-lg overflow-hidden hover:shadow-2xl' style={{width: '250px'}}>
        <div className="w-full h-44">
            <img className='img-fit' loading='eager' src={data.image_url} alt="" />
        </div>
        <div className="flex flex-col text-gray-500 py-3">
            <b>{data.name}</b>
            {
              hideButton ? (
                <div className='flex'>
                  <small>34 Votes</small>
                </div>
              ) : <small>2024</small>
            }
            
        </div>
        {
          hideButton ? '': (
            <Link to={`/awards/${data.id}`} className='w-full h-full'>
            <div className='w-full text-white text-center font-bold cursor-pointer rounded-sm rounded-b-lg  p-3 bg-[#e1862a] hover:bg-yellow-500  active:bg-yellow-700 focus:outline-none '>
              Select Event
            </div>
            </Link>
          )
        }
          
     
    </div>
  )
}

export default Card